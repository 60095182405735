import { fetcher } from '../../lib/Fetcher';

export function platformUrlSelector(platform: string | undefined) {
  switch (platform) {
    case 'karePedia': {
      return 'karepedia-articles';
    }
    case 'mykareBlog': {
      return 'articles';
    }
    case 'karetripBlog': {
      return 'karetrip-articles';
    }
    case 'default': {
      return null;
    }
  }
}

export function platformDomainSelector(platform: string | undefined) {
  switch (platform) {
    case 'karePedia': {
      return 'https://karepedia.com';
    }
    case 'mykareBlog': {
      return 'https://mykarehealth.com/kareline';
    }
    case 'karetripBlog': {
      return 'https://karetrip.com/blogs';
    }
  }
}

const platformUrl = platformUrlSelector(process.env.NEXT_PUBLIC_PLATFORM);

export const getTreatments = async () => {
  try {
    const response = await fetcher(`/treatments?populate=*`, {
      method: 'GET',
      next: { revalidate: 0 },
    });

    return response;
  } catch (error: any) {
    return {
      message: error.message,
      data: [],
    };
  }
};

export const getCategories = async () => {
  try {
    const response = await fetcher(
      `/categories?populate=*&filters[platforms][platformName][$eq]=${process.env.NEXT_PUBLIC_PLATFORM}&sort[0]=publishedAt:asc`,
      {
        method: 'GET',
        next: { revalidate: 0 },
      }
    );

    return response;
  } catch (error: any) {
    console.error(error.message);
    return {
      message: error.message,
      data: [],
    };
  }
};

export const getAuthorDetails = async (id: number) => {
  try {
    const response = await fetcher(`/authors/${id}?populate=profile_pic`, {
      method: 'GET',
      next: { revalidate: 300 },
    });

    return response;
  } catch (error: any) {
    return {
      message: error.message,
      data: [],
    };
  }
};

export const getArticlesDetails = async (id: number) => {
  try {
    const response = await fetcher(
      `/${platformUrl}/${id}?populate=card_image,cover_image,author,category`,
      {
        method: 'GET',
        next: { revalidate: 300 },
      }
    );

    return response;
  } catch (error: any) {
    return {
      message: error.message,
      data: [],
    };
  }
};

export const getArticlesDetailsBySlug = async (slug: String) => {
  try {
    const response = await fetcher(
      `/${platformUrl}?populate=card_image,cover_image,author,category,ogIcon,keyTakeaways&populate[1]=author.profile_pic&populate[2]=medicallyVerifiedBy.profile_pic&populate[3]=factCheckedBy.profile_pic&populate[4]=source${process.env.NEXT_PUBLIC_APP_STRAPI_LIVE_STATUS}&filters[slug][$eq]=${slug}`,
      {
        method: 'GET',
        next: { revalidate: 300 },
      }
    );

    return response;
  } catch (error: any) {
    return {
      message: error.message,
      data: [],
    };
  }
};

export const getArticlesDetailsCategorySlug = async (
  slug: string,
  search: string = '',
  pageCount: number
) => {
  try {
    const response = await fetcher(
      `/${platformUrl}?fields[0]=title&fields[1]=slug&fields[3]=readingTime&fields[4]=description&fields[5]=publishedAt&populate[0]=card_image&populate[1]=category&populate[2]=author&populate[3]=author.profile_pic&filters[category][slug][$eq]=${slug}&pagination[page]=${pageCount}&sort[0]=publishedAt:desc&pagination[pageSize]=25${process.env.NEXT_PUBLIC_APP_STRAPI_LIVE_STATUS}&filters[title][$contains]=${search}`,
      {
        method: 'GET',
        next: { revalidate: 300 },
      }
    );

    return response;
  } catch (error: any) {
    return {
      message: error.message,
      data: [],
    };
  }
};

export const getArticles = async (pageCount: number) => {
  try {
    const response = await fetcher(
      `/${platformUrl}?populate[0]=category&populate[1]=author.profile_pic&populate[2]=card_image&populate&sort[0]=publishedAt:desc&pagination[page]=${pageCount}&pagination[pageSize]=10${process.env.NEXT_PUBLIC_APP_STRAPI_LIVE_STATUS}`,
      {
        method: 'GET',
        next: { revalidate: 0 },
      }
    );

    return response;
  } catch (error: any) {
    return {
      message: error.message,
      data: [],
    };
  }
};

export const getFeatured = async () => {
  try {
    const response = await fetcher(
      `/${platformUrl}?fields[0]=title&fields[1]=readingTime&fields[2]=slug&populate[0]=category&populate[1]=author.profile_pic&populate[2]=cover_image&sort[0]=publishedAt:desc&filters[featured][$eq]=true${process.env.NEXT_PUBLIC_APP_STRAPI_LIVE_STATUS}`,
      {
        method: 'GET',
        next: { revalidate: 300 },
      }
    );

    return response;
  } catch (error) {
    console.error('===get Featured error===', error);
  }
};
export const getTopPicks = async () => {
  try {
    const response = await fetcher(
      `/${platformUrl}?populate[0]=card_image&populate[1]=author&populate[2]=category&sort[0]=publishedAt:desc&filters[top_picked][$eq]=true${process.env.NEXT_PUBLIC_APP_STRAPI_LIVE_STATUS}&pagination[start]=0&pagination[limit]=3`,
      {
        method: 'GET',
        next: { revalidate: 300 },
      }
    );

    return response;
  } catch (error) {
    console.error('===getArticles error===', error);
  }
};
export const getRecently = async () => {
  try {
    const response = await fetcher(
      `/${platformUrl}?fields[0]=title&fields[1]=slug&populate[1]=author&populate[2]=card_image${process.env.NEXT_PUBLIC_APP_STRAPI_LIVE_STATUS}&sort[0]=publishedAt:desc&pagination[start]=0&pagination[limit]=5`,
      {
        method: 'GET',
        next: { revalidate: 300 },
      }
    );

    return response;
  } catch (error) {
    console.error('===getArticles error===', error);
  }
};
export const getSearchResult = async (
  search: string,
  search2: string,
  search3: string
) => {
  try {
    const response = await fetcher(
      `/${platformUrl}?populate[0]=card_image&fields[0]=title&fields[1]=card_image&fields[2]=slug${process.env.NEXT_PUBLIC_APP_STRAPI_LIVE_STATUS}&filters[title][$containsi]=${search}&filters[description][$containsi]=${search2}&filters[content1][$containsi]=${search3}`,
      {
        method: 'GET',
        next: { revalidate: 300 },
      }
    );

    return response;
  } catch (error) {
    console.error('===getArticles error===', error);
  }
};
export const getRelated = async (category: string, slug: string) => {
  try {
    const response = await fetcher(
      `/${platformUrl}?fields[0]=title&fields[1]=slug&fields[3]=readingTime&populate[0]=card_image&populate[1]=category&filters[category][name][$eq]=${category}&filters[slug][$ne]=${slug}${process.env.NEXT_PUBLIC_APP_STRAPI_LIVE_STATUS}&pagination[start]=0&pagination[limit]=9`,
      {
        method: 'GET',
        next: { revalidate: 300 },
      }
    );

    return response;
  } catch (error) {
    console.error('===getArticles error===', error);
  }
};

export const getArticleSitemap = async () => {
  try {
    const response = await fetcher(
      `/${platformUrl}?fields[0]=slug&fields[1]=updatedAt&fields[2]=top_picked${process.env.NEXT_PUBLIC_APP_STRAPI_LIVE_STATUS}`,
      { method: 'GET', next: { revalidate: 300 } }
    );
    return response;
  } catch (error) {
    console.error('=====getArticleSitemap error=====', error);
  }
};
export const getCategorySitemap = async () => {
  try {
    const response = await fetcher(
      `/categories?fields[0]=slug&fields[1]=updatedAt`,
      { method: 'GET', next: { revalidate: 300 } }
    );
    return response;
  } catch (error) {
    console.error('=====getCategorySitemap error=====', error);
  }
};
