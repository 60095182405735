import { create } from 'zustand';

interface ctaStateType {
  ctaLabel: string;
  setCtaLabel: (ctaLabel: string) => void;
}

export const useGlobalStore = create<ctaStateType>()((set) => ({
  ctaLabel: '',
  setCtaLabel: (ctaLabel) =>
    set((state) => ({
      ...state,
      ctaLabel,
    })),
}));
