export const fetcher = async (
  url: string,
  opt: { method: string; next: object }
) => {
  const options: object = {
    ...opt,
    headers: {
      'Content-type': 'application/json',
    },
    // next: { revalidate:300 },
  };
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/api${url}`,
    options
  );

  return await response.json();
};
