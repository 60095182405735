'use client';
// import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
// const useForm = dynamic(() =>
//   import('react-hook-form').then((module: any) => module.useForm)
// );
// const Autocomplete = dynamic(() => import('@mui/material/Autocomplete'));
//   const TextField = dynamic(() => import('@mui/material/TextField'));
import { TreatmentData } from '@/utils/data/formGeneralData';
import { fetchLocationData } from '@/app/services/LocationService';
import { ValidationErrorMessage } from './ValidationErrorMessage';
import { saveEnquiry } from '@/app/services/enquiryService';
import useThemeStore from '@/app/stores/useThemeStore';
import { usePathname } from 'next/navigation';
import { useGlobalStore } from '@/app/stores/useGlobalStore';

const CustomForm = ({
  setOpenPopup,
  inputClass,
  btnBg,
  style,
  textColor,
  iconColor,
  backgroundColor = '#fff',
  inputTextColor,
  selectBgColor,
  btnBgHover,
  formLabel = '',
  cta = '',
}: any) => {
  const { ctaLabel, setCtaLabel } = useGlobalStore();

  // const [selectedTreatment, setSelectedTreatment] = useState(null);
  // const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedTreatment, setSelectedTreatment] = useState<{
    id: number;
  } | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<{
    id: number;
  } | null>(null);
  const [locations, setLocations] = useState<{ name: string; id: number }[]>();

  const { setShowToast }: any = useThemeStore((state) => state);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const locationData = await fetchLocationData();
        const locationName = locationData.map(
          (item: { name: string; id: number }) => ({
            name: item.name,
            id: item.id,
          })
        );
        return setLocations(() => locationName);
      } catch (error) {
        console.error('===fetch location error===', error);
      }
    };

    fetchData();
  }, []);

  const pathname = usePathname();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleOnSubmit = async ({
    name,
    phone,
    ctaLabel,
  }: {
    name: string;
    phone: number;
    ctaLabel: string;
  }) => {
    const sourceId = 25;
    let payload = {
      name: name,
      sourceId: sourceId,
      phoneNumber: '+91' + phone,
      platform: 'MYKARE_WEB',
      enquiryContext: 'TREATMENT_PACKAGE',
      enquiryContextId: selectedTreatment?.id,
      packageId: selectedTreatment?.id,
      locationId: selectedLocation?.id,
      url: `${process.env.NEXT_PUBLIC_APP_URL}${pathname}`,
      ctaLabel,
    };
    if (setOpenPopup) {
      setOpenPopup(false);
    }

    saveEnquiry(payload).then(() => {
      resetFields();

      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    });
  };
  const resetFields = () => {
    reset();
    setSelectedTreatment(null);
    setSelectedLocation(null);
  };
  const treatments = TreatmentData(process.env.NEXT_PUBLIC_PLATFORM);
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div
        className={`max-3xl:h-[56px] h-[56px] w-full rounded-[10px] border dark:border-gray-200 dark:bg-transparent max-2xl:h-[46px] max-sm:h-[56px] bg-[${backgroundColor}] text-[${inputTextColor}] ${inputClass} bg-[${selectBgColor}]`}
      >
        <input
          id='name'
          type='text'
          placeholder='Name'
          className={`max-3xl:h-[56px] h-[56px] w-full bg-transparent px-[14px] py-[20px] placeholder:text-[black] focus:outline-none  max-2xl:h-[46px] max-sm:h-[56px]`}
          style={style}
          {...register('name', {
            required: 'Name is required',
            pattern: {
              value: /^[A-Za-z ]+$/i,
              message: 'Name is not valid',
            },
          })}
        />
      </div>
      <div className='flex h-[20px] items-center'>
        {errors?.name && (
          <ValidationErrorMessage>
            {errors?.name?.message}
          </ValidationErrorMessage>
        )}
      </div>
      <div
        className={`max-3xl:h-[56px] grid h-[56px] w-full grid-cols-12 rounded-[10px] max-2xl:h-[46px] max-sm:h-[56px] ${inputClass} border dark:bg-transparent bg-[${backgroundColor}] text-[${inputTextColor}] ${inputClass} bg-[${selectBgColor}] focus:ring-0`}
      >
        <div
          className={`col-span-2 flex items-center justify-center bg-transparent`}
        >
          <span className='text-[#000]'>+91 |</span>
        </div>
        <input
          id='ctaLabel'
          type='text'
          hidden
          value={cta ? cta : ctaLabel}
          readOnly
          {...register('ctaLabel')}
        />
        <input
          id='phone'
          type='nummber'
          placeholder='PhoneNumber'
          className={`max-3xl:h-[56px] col-span-10 h-[56px] rounded-[0_10px_10px_0] bg-transparent placeholder:text-[black] focus:outline-none max-2xl:h-[46px] max-sm:h-[56px]`}
          style={style}
          {...register('phone', {
            required: 'Phone is required',
            pattern: {
              value: /^[0-9]+$/,
              message: 'invalid phone number',
            },
            min: {
              value: 1000000000,
              message: 'Phone number is too short',
            },
            max: {
              value: 9999999999,
              message: 'Phone number is too long',
            },
          })}
        />
      </div>

      <div className='flex h-[20px] items-center'>
        {errors?.phone && (
          <ValidationErrorMessage>
            {errors?.phone?.message}
          </ValidationErrorMessage>
        )}
      </div>

      <Autocomplete
        id='location'
        value={selectedLocation}
        options={locations ? locations : []}
        getOptionLabel={(option: any) => option.name}
        onChange={(e, val: any) => setSelectedLocation(val)}
        key={1}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Locations'
            {...register('location', {
              required: 'Location is required',
            })}
          />
        )}
        className={`max-3xl:h-[56px] flex h-[56px]  items-center rounded-[10px] border placeholder:text-[black] dark:border-gray-200 dark:bg-transparent max-2xl:h-[46px]  max-sm:h-[56px] bg-[${backgroundColor}] text-[${inputTextColor}] ${inputClass} bg-[${selectBgColor}]`}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: '0px !important',
          },
          '& .MuiInputBase-input': {
            color: `${textColor}`,
          },
          '& .MuiAutocomplete-popupIndicator': {
            color: `${iconColor}`,
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#000 !important',
          },
          '& input::placeholder': {
            color: '#1c2534',
            opacity: 1,
            outline: 'none',
          },
        }}
        style={style}
      />
      <div className='flex h-[20px] items-center'>
        {errors?.location && (
          <ValidationErrorMessage>
            {errors?.location?.message}
          </ValidationErrorMessage>
        )}
      </div>
      <Autocomplete
        id='treatment'
        options={treatments}
        value={selectedTreatment}
        groupBy={(option: any) => option.speciality}
        getOptionLabel={(option: any) => option.name}
        onChange={(e, val: any) => setSelectedTreatment(val)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Treatments'
            {...register('treatment', {
              required: 'Treatment is required',
            })}
          />
        )}
        className={`max-3xl:h-[56px] flex h-[56px] items-center rounded-[10px] border placeholder:text-[black] dark:border-gray-200 dark:bg-transparent max-2xl:h-[46px]  max-sm:h-[56px] bg-[${backgroundColor}] text-[${inputTextColor}] ${inputClass} bg-[${selectBgColor}]`}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: '0px !important',
          },
          '& .MuiInputBase-input': {
            color: `${textColor}`,
          },
          '& .MuiAutocomplete-popupIndicator': {
            color: `${iconColor}`,
          },
          '& input::placeholder': {
            color: '#1c2534',
            opacity: 1,
            outline: 'none',
          },
        }}
        style={style}
      />
      <div className='flex h-[20px] items-center'>
        {errors?.treatment && (
          <ValidationErrorMessage>
            {errors.treatment?.message}
          </ValidationErrorMessage>
        )}
      </div>

      <button
        type='submit'
        className={`bg-[${
          btnBg ? btnBg : '#1c2534'
        }] hover:bg-[${btnBgHover}] focus:bg-[${btnBgHover}] col-span-2 mt-[12px] h-[56px] w-full rounded-[12px] text-white`}
        style={style}
      >
        Book Your Consultation
      </button>
    </form>
  );
};
export default CustomForm;
