'use client';
import Image from 'next/image';
import { Modal } from '@mui/material';

import CustomForm from './CustomForm';

import closeBtn from '@/public/formpopup/icons/close-btn.svg';
import consultstionImg from '@/public/formpopup/consultstionImg.png';
import ConsultationsIcon from '@/public/formpopup/icons/consultation.png';
import SurgeriesIcon from '@/public/formpopup/icons/surgeries.png';
import PartnerHospitalIcon from '@/public/formpopup/icons/hospitals.png';
import CheckmarkIcon from '@/public/formpopup/icons/checkmark.png';
import FeatureImage from '@/public/BlogDetails/wepik-export-20230510100753YWWx@2x.webp';

export const FormPopup = ({
  openPopup,
  setOpenPopup,
  setShowToast,
  showTreatment = true,
  imgW,
}: any) => {
  const PopList = [
    {
      id: 1,
      title: '50K+',
      content: 'Consultations',
      icon: ConsultationsIcon,
    },
    {
      id: 2,
      title: '7000+',
      content: 'Surgeries',
      icon: SurgeriesIcon,
    },
    {
      id: 3,
      title: '100+',
      content: 'Partner Hospitals',
      icon: PartnerHospitalIcon,
    },
  ];

  const usps = [
    { id: 1, title: 'Expert Doctors' },
    { id: 2, title: 'End-to-end Care' },
    { id: 3, title: 'Insurance Support' },
    { id: 4, title: 'Treatment Organiser' },
  ];

  return (
    <Modal
      open={openPopup}
      onClose={() => setOpenPopup(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <>
        <section className='max-sm:hidden lg:block'>
          <section className='absolute left-1/2 top-1/2 w-[918px] -translate-x-1/2 -translate-y-1/2 transform rounded-[20px] bg-[#ffffff]'>
            <section className='flex w-full rounded-t-[20px] bg-[#00b4b9] p-[10px] '>
              <section className='w-full'>
                <h2
                  id='modal-modal-title'
                  className='text-center text-[24px] font-[500] leading-[1.96] tracking-[-0.52] text-[#fff]'
                >
                  Request a call back
                </h2>
              </section>
              <section className='me-2 flex items-center'>
                <button onClick={() => setOpenPopup(false)}>
                  <Image src={closeBtn} alt='clsoebtn' className='' />
                </button>
              </section>
            </section>
            <section className='flex'>
              <section className='m-[20px_0_0_40px] flex w-[50%] flex-col'>
                <section className=''>
                  <h3 className='w-[70%] text-left text-[26px] font-[600] leading-[1.36] tracking-[-0.56px] text-[#1c2534]'>
                    The surgery you need is just a click away
                  </h3>
                  <h3 className='text-left text-[16px] font-[500] leading-[2.38] tracking-[-0.32px] text-[#1c2534]'>
                    Consult with the most experienced Surgeons!
                  </h3>
                </section>
                <div className='mt-auto'>
                  <Image
                    src={consultstionImg}
                    alt='consultstionImg'
                    className={`w-[95%] w-[${imgW}]`}
                  />
                </div>
              </section>
              <section className='w-[50%] p-[30px_30px_30px_0]'>
                <section className='flex w-full items-end'>
                  {PopList?.map((item) => (
                    <section className='w-1/2' key={item.id}>
                      <section className='flex justify-center'>
                        <Image src={item?.icon} alt='icon' />
                      </section>
                      <h3 className='text-center text-[20px] font-bold leading-[1.6] tracking-[-0.4px] text-[#1c2534]'>
                        {item?.title}
                      </h3>
                      <h3 className='text-center text-[13px] font-[500] text-[#1c2534]'>
                        {item?.content}
                      </h3>
                    </section>
                  ))}
                </section>
                <section className='m-[30px_0_0_18px]'>
                  <CustomForm
                    buttonTitle='Book Appointment Now'
                    setOpenPopup={setOpenPopup}
                    setShowToast={setShowToast}
                    showTreatment={showTreatment}
                    inputClass='border-[#e8e8e8] border-[1px] bg-[#f5f6f9] text-[#1c2534]'
                  />
                </section>
              </section>
            </section>
          </section>
        </section>
        <section className='max-sx:block lg:hidden'>
          <section className='absolute left-1/2 top-1/2 h-auto w-[350px] -translate-x-1/2 -translate-y-1/2 transform rounded-[25px] bg-white'>
            <div className='relative flex h-[60px] items-center justify-center rounded-[20px_20px_0_0] bg-[#00cfaf] text-[#fff]'>
              <div className='w-full'>
                <h3 className='text-center text-[16px] font-[600] leading-[1.19] tracking-[-0.32px]'>
                  Book Your Appointment Now
                </h3>
              </div>
              <div className='absolute right-[15px]'>
                <button onClick={() => setOpenPopup(false)}>
                  <Image src={closeBtn} alt='clsoebtn' className='w-[14px]' />
                </button>
              </div>
            </div>
            <div className='px-[25px] pt-[25px]'>
              <section className='mb-[35px]'>
                <CustomForm
                  inputClass='border-[#e8e8e8] border-[1px] bg-[#f5f6f9] text-[#1c2534]'
                  setOpenPopup={setOpenPopup}
                  setShowToast={setShowToast}
                  showTreatment={showTreatment}
                  buttonTitle='Book Appointment Now'
                />
              </section>
              <section
                style={{
                  backgroundImage: `url(${FeatureImage.src})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPositionX: 'right',
                  height: 190,
                  width: 'auto',
                }}
                className=''
              >
                {usps?.map((list) => (
                  <section
                    className='my-[8px] flex items-center'
                    key={list?.id}
                  >
                    <Image
                      src={CheckmarkIcon}
                      alt=''
                      className='h-[16px] w-auto'
                    />
                    <h3 className='ml-[8px] text-center text-[13px] font-semibold leading-[2.6] tracking-[-0.13px]'>
                      {list?.title}
                    </h3>
                  </section>
                ))}
              </section>
            </div>
          </section>
        </section>
      </>
    </Modal>
  );
};
